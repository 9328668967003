<template>
    <div class='blog-articles'>
        <div class='filter__container'>
            <div class='row mb-3 justify-content-between'>
                <div class='input-group col-12 col-xl-6'>
                    <input type='search' class='form-control' v-model='searchWord' id='search-input'>
                    <span class='input-group-append'>
                        <button type='button' class='btn btn-default' @click='search'>Искать</button>
                    </span>
                </div>

                <div class='col-6 col-xl-2 mt-3 mt-xl-0 text-right'>
                    <button type="button" class='btn btn-primary' @click='createNewArticle'>Создать категорию</button>
                </div>
            </div>
        </div>

        <div class='blog-articles-list widget'>
            <div class='table_wrapper'>
                <BlogCategoriesTable
                    v-if='getBlogCategories'
                    :categories='getBlogCategories'
                    :delete-row='deleteRow'
                />
            </div>
        </div>

        <div class='overflow-auto'>
            <b-pagination
                class='paginator'
                v-model='currentPage'
                :total-rows='getPageMeta.total'
                :per-page='getPageMeta.per_page'
                aria-controls='my-table'
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';
import { debounce } from 'debounce';
import notificationMixin from '@/mixins/notificationMixin';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import VueMultiselect from 'vue-multiselect';
import DeleteModal from '@/components/Modal/DeleteModal';
import Switcher from '@/components/Switcher';
import Preloader from '@/components/Preloader';
import BlogCategoriesTable from '@/components/Blog/Category/BlogCategoriesTable.vue';

export default {
    name: 'BlogArticles',
    mixins: [notificationMixin],
    components: {
        Preloader,
        Switcher,
        VCalendar,
        VueMultiselect,
        BlogCategoriesTable,
    },
    data() {
        return {
            routerParams: { ...this.$route.query },
            currentPage: this.$route.query.page || 1,
            rows: 125,
            perPage: 25,
            searchWord: this.$route.query.search || '',
            debounceCooldown: null,
            showFilter: false,
        };
    },
    async created() {
        if (Object.keys(this.$route.query).filter(item => item !== 'search' || item !== 'page').length > 0) {
            this.showFilter = true;
        }
        await this.fetchBlogCategories();
        dayjs.extend(duration);
        dayjs.locale(ru);
    },
    computed: {
        ...mapGetters({
            getBlogCategories: 'blogCategory/getBlogCategories',
            getPageMeta: 'blogCategory/getPageMeta',
        }),
    },
    watch: {
        currentPage(value) {
            this.routerParams['page'] = value;
            this.updateRouter();
            this.fetchBlogCategories();
        },
        // searchWord(value) {
        //     this.debounceSearch();
        // },
    },
    methods: {
        ...mapActions({
            loadBlogCategories: 'blogCategory/loadBlogCategories',
            findBlogCategories: 'blogCategory/findBlogCategories',
            deleteBlogCategory: 'blogCategory/deleteBlogCategory',
        }),
        createNewArticle() {
            this.$router.push({ name: 'BlogCategoryCreate' });
        },
        deleteRow(id, name, index) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: async () => {
                        try {
                            const { data } = await this.deleteBlogCategory(id);
                            if (data.success) {
                                this.$toasted.success(`Категория удалена`, {position: "bottom-right"});
                                this.fetchBlogCategories();
                            } else {
                                this.$toasted.error(data.message ? data.message : 'Не удалось удалить категорию блога');
                            }
                        } catch (e) {
                            if (e.response && e.response.data) {
                                this.$toasted.error(e.response.data);
                            }
                            console.error(e);
                        }

                    },
                }, { name: 'DeleteModal' },
            );
        },
        loadMore() {
            this.loadBlogCategories({ page: this.getNextPage });
        },
        async handleCheckBoxFilter(type) {
            if (this.searchWord.length > 0) this.searchWord = null;
            this.updateRouter();
            await this.fetchBlogCategories();
        },
        async handleUTM(e) {
            if (this.searchWord.length > 0) this.searchWord = null;
            this.updateRouter();
            await this.fetchBlogCategories();
        },
        async searchByEnter(e) {
            if (this.searchWord.length > 2) {
                if (e.keyCode == 13) {
                    await this.findBlogCategories(this.searchWord);
                }
            }
        },
        async search() {
            // if (this.searchWord.length > 2) {
                await this.findBlogCategories(this.searchWord);
            // }
        },
        updateRouter() {
            this.$router.push({ name: 'BlogCategories', query: this.routerParams });
        },
        fetchBlogCategories: debounce(async function() {
            await this.loadBlogCategories(this.$route.query);
        }, 300),
    },
};
</script>

<style lang='scss' scoped>
.badge-copy {
    background: rgba(#000, .1);
}

.btn .glyphicon {
    top: 0 !important;
}

.table_wrapper {
    overflow-x: auto;
    position: relative;
}

.table_head, th {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
}

.actions {
    max-width: 100px;
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-button {
    font-weight: 700;
    color: black;
    background: #fff;
    padding: 0 20px;
    border-radius: 5px 5px 0 0;
    position: relative;
    z-index: 1000;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -30px;
        left: 0;
        background: #fff;
        width: 100%;
        height: 30px;
    }
}

.clear-input {
    position: absolute;
    right: 10px;
    color: lighten(red, 20);
    top: 50%;
    transform: translateY(-50%);
}

.badge {
    border-radius: 5px
}
</style>